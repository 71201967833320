
import './css/main.css';

import './css/fullpage.css';

import barba from '@barba/core';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import 'vidstack/player';
import 'vidstack/player/layouts/default';
import 'vidstack/player/ui';






barba.init({
    debug: true,
    timeout: 7000,
    transitions: [
        {
            name: 'opacity-transition',
            leave(data) {
                return gsap.to(data.current.container, {
                    opacity: 0
                });
            },
            // * MA added this beforeEntry bit
            beforeEnter: function beforeEnter(data) {
                
            },
            enter(data) {
            },
            after(data) {
                return gsap.from(data.next.container, {
                    opacity: 0
                });
            },
            afterOnce(data) {
            },
            once(data) {
            }
        }
    ],
});

 // * MA added this reloadSprig bit
// const reloadSprig = () => {
//     const $sprigComponents = document.querySelectorAll('.sprig-component');

//     if($sprigComponents.length > 0) {
// 	$sprigComponents.forEach($sprigComponent => {
// 	    window.htmx.process($sprigComponent);
// 	});
//     }
// };

function fpCheck(data) {
    let fullP = document.getElementById('fullpage');
    if (fullP.length > 0) {
        import(/* webpackChunkName: "fullpage" */ "./js/components/fullpage.js").then(module => {
            // const gridIt = module.default;
            // gridIt(data);
        });
    }
}


function scrollToTop() {
// window.scrollTo({top: 0, behavior: 'smooth'});
window.scrollTo({top: 0});
};

function gridCheck(data) {
    let grids = document.getElementsByClassName('masGrid');
    if (grids.length > 0) {
        import(/* webpackChunkName: "grid" */ "./js/components/grid.js").then(module => {
            const gridIt = module.default;
            gridIt(data);
        });
    }
}

function projectsCheck(data) {
        import(/* webpackChunkName: "projectsMenu" */ "./js/components/projectsMenu.js").then(module => {
            const projIt = module.default;
            projIt(data);
        });
}

function galleryCheck(data) {
    let gallery = document.getElementsByClassName('gallery');
    if (gallery.length > 0) {
        import(/* webpackChunkName: "gallery" */ "./js/components/gallery.js").then(module => {
            const galleryIt = module.default;
            galleryIt(data);
        });
    }
}

function mapCheck(data) {
    let map = document.getElementsByClassName('contactMap');
    if (map.length > 0) {
    import(/* webpackChunkName: "map" */ "./js/components/map.js").then(module => {
        const mapIt = module.default;
        mapIt(data);
    });
    }
}

function projectImageHover() {
    let projectImageDiv = document.getElementsByClassName('projectImage')
    let projectText = document.getElementsByClassName('projImageHover');
    if (projectImageDiv.length > 0 && projectText.length > 0) {
        import(/* webpackChunkName: "projectImageHover" */ "./js/components/projectHover.js").then(module => {
            const projHovIt = module.default;
            projHovIt();
        });
    }
}

function projIndexList() {
    let listItems = document.getElementsByClassName('projectIndexListItems');
    if (listItems.length > 0) {
        import(/* webpackChunkName: "projIndexList" */ "./js/components/projIndexList.js").then(module => {
            const projListIt = module.default;
            projListIt();
        });
    }
}

barba.hooks.once((data) => {
    projectsCheck(data);
    galleryCheck(data);
    // reloadSprig();
    projectImageHover();
    projIndexList();
});

barba.hooks.afterOnce((data) => {
    mapCheck(data);
    gridCheck(data);
    setTimeout(() => {gridCheck(data)}, 500);
    
});

barba.hooks.before((data) => {
    fpCheck(data);
})

barba.hooks.enter((data) => {
    projectsCheck(data);
})

barba.hooks.beforeEnter((data) => {
    scrollToTop();
});


barba.hooks.after((data) => {
    mapCheck(data);
    gridCheck(data);
    fpCheck(data);
    galleryCheck(data);
    // reloadSprig();
    projectImageHover();
    projIndexList();
});
